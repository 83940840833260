exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-beauty-is-power-tsx": () => import("./../../../src/pages/beauty-is-power.tsx" /* webpackChunkName: "component---src-pages-beauty-is-power-tsx" */),
  "component---src-pages-contact-info-tsx": () => import("./../../../src/pages/contact-info.tsx" /* webpackChunkName: "component---src-pages-contact-info-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-how-it-works-index-tsx": () => import("./../../../src/pages/how-it-works/index.tsx" /* webpackChunkName: "component---src-pages-how-it-works-index-tsx" */),
  "component---src-pages-how-it-works-our-impact-tsx": () => import("./../../../src/pages/how-it-works/our-impact.tsx" /* webpackChunkName: "component---src-pages-how-it-works-our-impact-tsx" */),
  "component---src-pages-how-it-works-the-business-model-tsx": () => import("./../../../src/pages/how-it-works/the-business-model.tsx" /* webpackChunkName: "component---src-pages-how-it-works-the-business-model-tsx" */),
  "component---src-pages-how-it-works-the-sustainable-network-tsx": () => import("./../../../src/pages/how-it-works/the-sustainable-network.tsx" /* webpackChunkName: "component---src-pages-how-it-works-the-sustainable-network-tsx" */),
  "component---src-pages-i-have-finally-found-my-hair-heroes-tsx": () => import("./../../../src/pages/i-have-finally-found-my-hair-heroes.tsx" /* webpackChunkName: "component---src-pages-i-have-finally-found-my-hair-heroes-tsx" */),
  "component---src-pages-i-want-to-have-a-bigger-salon-and-busy-life-tsx": () => import("./../../../src/pages/i-want-to-have-a-bigger-salon-and-busy-life.tsx" /* webpackChunkName: "component---src-pages-i-want-to-have-a-bigger-salon-and-busy-life-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-its-a-human-issue-tsx": () => import("./../../../src/pages/its-a-human-issue.tsx" /* webpackChunkName: "component---src-pages-its-a-human-issue-tsx" */),
  "component---src-pages-maintain-what-you-have-by-giving-it-away-tsx": () => import("./../../../src/pages/maintain-what-you-have-by-giving-it-away.tsx" /* webpackChunkName: "component---src-pages-maintain-what-you-have-by-giving-it-away-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-who-we-are-beauty-educators-tsx": () => import("./../../../src/pages/who-we-are/beauty-educators.tsx" /* webpackChunkName: "component---src-pages-who-we-are-beauty-educators-tsx" */),
  "component---src-pages-who-we-are-beauty-students-tsx": () => import("./../../../src/pages/who-we-are/beauty-students.tsx" /* webpackChunkName: "component---src-pages-who-we-are-beauty-students-tsx" */),
  "component---src-pages-who-we-are-index-tsx": () => import("./../../../src/pages/who-we-are/index.tsx" /* webpackChunkName: "component---src-pages-who-we-are-index-tsx" */),
  "component---src-pages-why-beauty-tsx": () => import("./../../../src/pages/why-beauty.tsx" /* webpackChunkName: "component---src-pages-why-beauty-tsx" */)
}

